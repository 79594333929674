<template>
  <v-container fluid>
    <v-btn style="position: absolute; bottom: 16px" color="accent" @click="$router.go(-1)">voltar</v-btn>
    <v-row>
      <v-col cols="12" sm="4">
        <div class="text-h4 ml-1 mt-2">{{ user.username }}</div>
      </v-col>
      <v-col cols="12" sm="8">
        <v-chip large>Denúncias Publicadas <v-chip class="ml-2 " color="#a6a6a6">
            <div style="font-size: 16px;">
              {{ denunciasPublicadas }}
            </div>
          </v-chip>
        </v-chip>
        <v-chip class="ml-6" large>Denúncias Análise <v-chip class="ml-2 " color="#a6a6a6">
            <div style="font-size: 16px;">
              {{ denunciasUsuario.length - denunciasPublicadas }}
            </div>
          </v-chip>
        </v-chip>
      </v-col>
    </v-row>
    <v-row>
      <!-- First column taking 3 columns -->
      <v-col cols="12" sm="4">
        <!-- Content for the first column -->
        <v-list subheader class="list-wrapper">
          <v-subheader>Informações</v-subheader>

          <div v-if="isAdmin && user.role.type == 'authenticated'">
            <v-btn color="green" style="color: white;" class="ml-2" @click="mudarAdmin()">
              TORNAR ADMIN
            </v-btn>
          </div>

          <div v-if="isAdmin && user.role.type == 'admin_350'">
            <v-btn color="error" style="color: white;" class="ml-2" @click="mudarAdmin()">
              TIRAR ADMIN
            </v-btn>
          </div>



          <!-- MODERADOR -->
          <v-list-item v-if="user.role.type == 'admin_350'">
            <v-list-item-avatar>
              <v-icon color="green">mdi-shield-crown</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              Moderador
            </v-list-item-content>
          </v-list-item>

          <!-- E-MAIL -->

          <v-list-item>
            <v-list-item-avatar>
              <v-icon>mdi-email</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title> {{ user.email }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- CRIADO EM -->
          <v-list-item>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-avatar v-bind="attrs" v-on="on">
                  <v-icon>mdi-clock</v-icon>
                </v-list-item-avatar>

              </template>
              <span>Criado em</span>
            </v-tooltip>

            <v-list-item-content>
              <v-list-item-title> {{ new Date(user.createdAt).toLocaleDateString(
                "pt-BR",
                { year: "numeric", month: "2-digit", day: "2-digit" }
              ) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- ASSOCIACAO  -->
          <v-list-item>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-avatar v-bind="attrs" v-on="on">
                  <v-icon>mdi-account-group</v-icon>
                </v-list-item-avatar>

              </template>
              <span>Associação</span>
            </v-tooltip>


            <v-list-item-content>
              <v-list-item-title> {{ user.associacao ? user.associacao : "--" }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- TELEFONE -->
          <v-list-item>
            <v-list-item-avatar>
              <v-icon>mdi-phone</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title> {{ user.telefone ? user.telefone : "--" }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>


          <!-- CIDADE  -->
          <v-list-item>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-avatar v-bind="attrs" v-on="on">
                  <v-icon>mdi-home-city</v-icon>
                </v-list-item-avatar>

              </template>
              <span>Cidade e Estado</span>
            </v-tooltip>


            <v-list-item-content>
              <v-list-item-title> {{ user.cidade ? user.cidade : "--" }} , {{ user.estado ? user.estado : "--"
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>



        </v-list>
      </v-col>
      <v-col cols="12" sm="8">


        <v-data-table :headers="headers" :items="denunciasUsuario" :items-per-page="10" show-expand :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc" :expanded.sync="expanded" locale="pt" class="elevation-1">
          <template v-slot:[`item.publicado`]="{ item }">
            <!-- <div style="position: flex; justify-content: center;">

              <v-btn @click="openDialog(item)">Editar</v-btn>
              <v-btn color="accent ml-6" @click="publicar(item)">Publicar</v-btn>
              <v-btn color="error ml-6" @click="openDeleteDialog(item)">Deletar</v-btn>
            </div> -->
            <div v-if="item.publicado">
              <v-chip color="sucess">Publicado</v-chip>
            </div>
            <div v-else>
              <v-chip color="error">
                Em análise
              </v-chip>
            </div>
          </template>
          <template v-slot:[[`item.publishedAt`]]="{ item }">
            <span>{{ new Date(item.publishedAt).toLocaleString() }}</span>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-row class="my-2">
                <v-col>

                  <div><b>Categoria: </b> {{ item.categories }}</div>
                  <div><b>Elemento: </b> {{ item.elements }}</div>
                  <div><b>Impactos: </b> {{ item.impacts }}</div>
                  <div v-if="item.photo"><b>fotos: </b> {{ item.photo.length }}</div>
                  <div v-if="item.relato"><b>Relato: </b> {{ item.relato }}</div>
                </v-col>
                <v-col cols="text-center align-center">
                  <div class="text-center" style="width: 100%" v-if="item.photo">
                    <v-img v-if="item.photo.length == 1" height="300px" width="300" :src="`${item.photo[0].url}`">
                    </v-img>


                    <v-carousel v-else class="carousel-img" cycle height="300" width="300" hide-delimiters
                      hide-delimiter-background>
                      <v-carousel-item v-for="(slide, i) in item.photo" :key="i" :src="getSrcImg(slide)" />
                    </v-carousel>
                  </div>
                </v-col>

              </v-row>
            </td>
          </template>
        </v-data-table>



      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import request from "@/router/requests.js";
import { isAdmin } from '@/auth'
export default {
  name: "Usuario",
  props: {
    usuarios: Array,
    denuncias: Array
  },
  data() {
    return {
      user: {},
      userData: {},
      apiUrl: process.env.VUE_APP_STRAPI_URL,
      expanded: [],
      sortBy: "publishedAt",
      sortDesc: true,
      headers: [
        {
          text: "Codigo da denúncia",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "Publicada",
          align: "start",
          sortable: true,
          value: "publicado",
        },
        {
          text: "Data da criação",
          align: "start",
          sortable: true,
          value: "publishedAt",
        },
        {
          text: "",
          value: "data-table-expand",
        },
      ],
    }
  },
  methods: {
    async mudarAdmin() {
      const obj = this.user;

      obj.role = this.user.role.type == 'authenticated' ? 3 : 1;
      await request("PUT", `users/${this.user.id}`, obj).then((res) => {
        if (res.role) {
          if (res.role == 1) {
            this.user.role = {
              type: 'authenticated',
              id: 1
            }
          }
          if (res.role == 3) {
            this.user.role = {
              type: 'admin_350',
              id: 3
            }
          }
        }
      })
    },
    getSrcImg(photo) {

      if (photo.formats.length > 1) {
        return photo.formats.small.url
      } else {
        return photo.url
      }
    },
  },
  computed: {
    isAdmin() {
      return isAdmin ? true : false
    },
    denunciasUsuario() {
      return this.denuncias.filter((x) =>
        x.users_permissions_user.id == this.user.id
      )
    },
    denunciasPublicadas() {
      return this.denunciasUsuario.filter(item => item.publicado).length;
    }
  },
  async created() {
    const user = this.usuarios.filter(x => {
      return x.id == this.$route.params.id
    })
    this.user = user[0];
    this.userData = JSON.parse(localStorage.getItem("userData"));
    if (this.userData.role.id != 4) {
      await request("GET", `users/${this.$route.params.id}?populate=*`).then((x) => {
        this.user = x
      })
    }

  }
}

</script>

<style scoped>
.list-wrapper {
  background-color: rgb(242, 242, 242);
  border-radius: 16px;
}

.carousel-img {
  width: 350px;
}
</style>